import Link from 'next/link'

export default function DisablePrefetchLink({
  children,
  ...args
}) {

  return (
    <Link {...args} prefetch={false}>{children}</Link>
  )
}